; (function () {

    var Chat = {

        init: function (config) {
            this.config = config;
            this.bindEvents();
        },

        bindEvents: function () {
            // Bind click event to live chat links with a class of .chat-button
            this.config.chatButton.on('click', this.openChatBox);
        },

        openChatBox: function (e) {
            $zopim.livechat.window.show();
        }
    };

    Chat.init({
        chatButton: $('.chat-button')
    });

})();


window.$zopim || (function (d, s) {
    var z = $zopim = function (c) { z._.push(c) }, $ = z.s =
    d.createElement(s), e = d.getElementsByTagName(s)[0]; z.set = function (o) {
        z.set.
        _.push(o)
    }; z._ = []; z.set._ = []; $.async = !0; $.setAttribute("charset", "utf-8");
    $.src = "//v2.zopim.com/?3P67aOLihOA9oOH7vfnKArZOIFYtJ4Qy"; z.t = +new Date; $.
    type = "text/javascript"; e.parentNode.insertBefore($, e)
})(document, "script");

